import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/company/recharge/withdraw/list`,
    method: 'get',
    params
  })
}
export function getChargeListAPI(params) {
  return http({
    url: `/company/recharge/withdraw/order/list`,
    method: 'get',
    params
  })
}

export function delAPI(id) {
  return http({
    url: `/api/company/travelogue/del`,
    method: 'post',
    data: { id }
  })
}
export function addAPI(data) {
  return http({
    url: `/api/company/travelogue/add`,
    method: 'post',
    data
  })
}
export function reviewAPI(data) {
  return http({
    url: `/company/recharge/withdraw/audit`,
    method: 'post',
    data
  })
}

export function editAPI(data) {
  return http({
    url: `/api/company/travelogue/edit`,
    method: 'post',
    data
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/api/company/travelogue/detail`,
    method: 'get',
    params: { id }
  })
}
// 手动退款
export function handleTuikuanAPI(order_id) {
  return http({
    url: `/company/recharge/withdraw/order/refund`,
    method: 'get',
    params: { order_id }
  })
}
