<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="用户ID">
          <el-input style="width: 180px" clearable v-model="searchData.uid" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input style="width: 180px" clearable v-model="searchData.mobile" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <!-- <el-form-item label="申请时间">
          <el-date-picker
            style="width: 210px"
            v-model="searchData.created_at_range"
            size="mini"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabs">
      <!-- <div :class="['item', searchData.status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div> -->
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">待审核</span>
        </el-badge>
      </div>
      <!-- <div :class="['item', searchData.status === 1 ? 'active' : '']" @click="handleClickTab(1)">
        <el-badge>
          <span class="tab-name">已通过</span> </span>
        </el-badge>
      </div> -->
      <div :class="['item', searchData.status === 2 ? 'active' : '']" @click="handleClickTab(2)">
        <el-badge>
          <span class="tab-name">已拒绝</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 3 ? 'active' : '']" @click="handleClickTab(3)">
        <el-badge>
          <span class="tab-name">已退款</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 4 ? 'active' : '']" @click="handleClickTab(4)">
        <el-badge>
          <span class="tab-name">退款失败</span>
        </el-badge>
      </div>
    </div>

    <el-table :data="list">
      <el-table-column prop="account_name" label="用户信息" min-width="160">
        <template slot-scope="{ row }">
          <div class="user-box">
            <div class="row">
              <span>ID：</span>
              <el-link type="primary" @click="$router.push({ name: 'UserVipList', query: { id: row.user.id } })">{{ row.user.id }}</el-link>
            </div>
            <div class="row">
              <span>昵称：</span>
              <span>{{ row.user.nickname }}</span>
            </div>
            <div class="row">
              <span>手机号：</span>
              <span>{{ row.user.mobile }}</span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="account_number" label="状态" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="small">待审核</el-tag>
          <el-tag v-else-if="row.status == 1" type="primary" size="small">已通过</el-tag>
          <el-tag v-else-if="row.status == 2" type="danger" size="small">已拒绝</el-tag>
          <el-tag v-else-if="row.status == 3" type="success" size="small">已退款</el-tag>
          <el-tag v-else-if="row.status == 4" type="danger" size="small">退款失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="提现金额" min-width="120"> </el-table-column>
      <el-table-column prop="before_balance" label="账户余额" min-width="160">
        <template slot-scope="{ row }">
          <div class="info-box">
            <div class="row">
              <span>提现前余额：</span>
              <span>{{ row.before_balance }}</span>
            </div>
            <div class="row">
              <span>提现后余额：</span>
              <span>{{ row.after_balance }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="after_lock_balance" label="冻结金额" min-width="160">
        <!-- <template slot-scope="{ row }">
          <div class="info-box">
            <div class="row">
              <span>提现前余额：</span>
              <span>{{ row.before_lock_balance }}</span>
            </div>
            <div class="row">
              <span>提现后余额：</span>
              <span>{{ row.after_lock_balance }}</span>
            </div>
          </div>
        </template> -->
      </el-table-column>

      <el-table-column prop="content" label="备注" min-width="120"> </el-table-column>
      <el-table-column prop="created_at" label="申请时间" min-width="150"> </el-table-column>
      <el-table-column v-if="searchData.status >= 3" prop="updated_at" label="更新时间" min-width="150">
        <template slot-scope="{ row }">
          {{ row.updated_at || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="id" label="操作" width="140" fixed="right">
        <template slot-scope="{ row }">
          <el-link
            v-checkbtn="isShowBtn(AUTH_BTN.recharge_value_review)"
            v-if="row.status == 0"
            type="primary"
            :underline="false"
            @click="review(row)"
            >审核</el-link
          >
          <el-link
            v-checkbtn="isShowBtn(AUTH_BTN.recharge_value_tuikuan_detail)"
            v-if="row.status >= 3"
            type="primary"
            :underline="false"
            @click="handleTuikuan(row)"
            >退款详情</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 审核 -->
    <el-dialog title="审核" :visible.sync="show_status" width="950px">
      <el-form class="status" ref="status" :model="statusData" label-width="120px">
        <el-form-item label="状态：">
          <el-radio-group v-model="statusData.status">
            <el-radio :label="1">审核通过</el-radio>
            <el-radio :label="2">审核拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="statusData.status == 1" label="充值订单：">
          <el-table :data="charge_list" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" :selectable="selected" width="55"> </el-table-column>
            <el-table-column prop="pay_amount" label="充值金额" min-width="100"> </el-table-column>
            <el-table-column prop="surplus_amount" label="可退款金额" min-width="100"> </el-table-column>
            <el-table-column prop="order_no" label="订单号" min-width="110" />
            <el-table-column prop="pay_time" label="支付时间" min-width="110" />
            <el-table-column prop="id" label="退款金额" min-width="110">
              <template slot-scope="{ row }">
                <el-input-number
                  v-model="row.money"
                  size="mini"
                  :precision="2"
                  :step="0.01"
                  :min="0"
                  :max="Number(row.surplus_amount)"
                ></el-input-number>
              </template>
            </el-table-column>
          </el-table>
          <pagination :total="recharge_total" :page.sync="recharge_page" :limit.sync="recharge_page_size" @pagination="getChargeList" />
          <div class="total">
            <div class="item">
              申请退款金额：<span style="color: #f56c6c; font-weight: 700">{{ current.money }}</span> 元
            </div>
            <div class="item">
              当前退款金额：<span style="color: #f56c6c; font-weight: 700">{{ current_money }}</span> 元
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_status = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="confirmSatatus" size="mini">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 手动退款 -->
    <el-dialog title="手动退款" :visible.sync="show_tuikuan" width="950px">
      <div style="margin-bottom: 10px">
        用户申请退款金额：<span style="color: red; font-weight: 600">{{ current_order.money }}</span> 元
      </div>
      <el-table :data="current_order.orders" style="width: 100%">
        <el-table-column prop="refund_no" label="流水号" min-width="170"> </el-table-column>
        <el-table-column prop="money" label="金额" min-width="80"> </el-table-column>
        <el-table-column prop="status_dsc" label="状态" min-width="90"> </el-table-column>
        <el-table-column prop="created_at" label="创建时间" min-width="140"> </el-table-column>
        <el-table-column prop="err_code_des" label="描述" min-width="150"> </el-table-column>
        <el-table-column v-if="current_order.status == 4" prop="created_at" label="操作" width="100">
          <template slot-scope="{ row }">
            <el-link
              v-checkbtn="isShowBtn(AUTH_BTN.recharge_value_tuikuan)"
              v-if="row.status == 3"
              type="danger"
              :underline="false"
              @click="confirmTuikuan(row)"
              >退款</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <!-- <el-button @click="show_tuikuan = false">取 消</el-button> -->
        <el-button type="primary" size="mini" @click="show_tuikuan = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getListAPI, reviewAPI, getChargeListAPI, handleTuikuanAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],

  data() {
    return {
      show_tuikuan: false,
      show_status: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        uid: '',
        status: 0,
        mobile: ''
        // created_at_range: ''
      },
      total: 0,
      statusData: {
        id: '',
        status: 1,
        order_list: []
      },
      charge_list: [],
      select_list: [],
      current: {},
      recharge_page: 1,
      recharge_page_size: 10,
      recharge_total: 1,
      current_order: [] //退款失败订单
    }
  },
  computed: {
    current_money() {
      return (
        this.select_list.reduce((pre, cur) => {
          return pre + cur.money * 100
        }, 0) / 100
      )
    }
  },
  watch: {},
  activated() {},
  mounted() {
    this.getList()
  },

  methods: {
    handleSelectionChange(val) {
      this.select_list = val
    },
    async getList() {
      const { page, page_size, status, created_at_range, account_name, account_number, mobile } = this.searchData
      let params = {}
      if (status == -1) {
        params = {
          page,
          page_size,
          created_at_range,
          account_name,
          account_number,
          mobile
        }
      } else {
        params = {
          page,
          page_size,
          status,
          created_at_range,
          account_name,
          account_number,
          mobile
        }
      }

      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    // 审核按钮
    async review(row) {
      this.current = row
      this.statusData = { id: row.id, status: 1, order_list: [] }
      this.show_status = true
      this.getChargeList()
    },
    // 充值订单列表
    async getChargeList() {
      const params = {
        page: this.recharge_page,
        page_size: this.recharge_page_size,
        id: this.current.id,
        pay_status: 2,
        uid: this.current.uid
      }
      const res = await getChargeListAPI(params)
      this.charge_list = res.data.map((item) => {
        return {
          ...item,
          money: ''
        }
      })
      this.recharge_total = res.total
    },
    // 可退款金额等于0 不可选
    selected(row, index) {
      if (row.surplus_amount <= 0) {
        return false
      } else {
        return true
      }
    },
    // 提交审核
    confirmSatatus() {
      const { id, status } = this.statusData
      if (status == 1) {
        if (this.select_list.length == 0) return this.$message.warning('请选择要退款的订单')
        // if (this.current_money != this.current.money) return this.$message.warning('退款金额必须等于申请退款金额')
      }

      const order_list = this.select_list.map((item) => {
        return {
          order_id: item.id,
          money: item.money
        }
      })

      let data = {}
      if (status == 1) {
        data = { id, status, order_list }
      }
      if (status == 2) {
        data = { id, status, order_list: [] }
      }
      reviewAPI(data).then(() => {
        this.$message.success('提交成功')
        this.getList()
        this.show_status = false
      })
    },
    // 点击tab
    handleClickTab(i) {
      if (this.searchData.status === i) return
      this.searchData.status = i
      this.getList()
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 手动退款
    handleTuikuan(item) {
      this.current_order = item
      this.show_tuikuan = true
    },
    confirmTuikuan({ id }) {
      this.$confirm('确认退款吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handleTuikuanAPI(id).then(() => {
            this.$message.success('提交成功')
            this.getCurrentOrder()
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    async getCurrentOrder() {
      const res = await getListAPI({ id: this.current_order.id })
      this.current_order = res.data[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 15px;
        margin-bottom: 10px;
      }
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
  .status /deep/ {
    .el-table {
      .el-table__header-wrapper,
      .el-table__fixed-header-wrapper {
        th {
          word-break: break-word;
          background-color: #f8f8f9 !important;
          color: #515a6e;
          height: 40px;
          font-size: 13px;
        }
      }

      .el-table__body-wrapper {
        .el-button [class*='el-icon-'] + span {
          margin-left: 1px;
        }
      }

      .el-table__fixed {
        height: 100% !important;
      }
      .el-table__fixed-right {
        height: 100% !important;
      }
    }
    .total {
      display: flex;

      flex-direction: column;
      .item {
        margin-right: 20px;
      }
    }
  }
}
</style>
